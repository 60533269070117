import { camelCase } from 'lodash';

export const pascalCase = (str) => {
    if (!str || typeof str !== 'string') {
        return str; //return the original value if not a string
    }

    const res = camelCase(str);
    return ucFirstLetter(res);
};

export const ucFirstLetter = (str) => {
    if (!str || typeof str !== 'string') {
        return str; //return the original value if not a string
    }
    
    const firstLetter = str.charAt(0).toUpperCase();
    return firstLetter + str.slice(1);
};
